'use client';

import { useState, useEffect } from 'react';
import type { ButtonT } from './types';

export const useButtonState = () => {
  const [state, setState] = useState<ButtonT['state']>();

  useEffect(() => {
    const displayTempState = state && state !== 'loading';
    const timeout = displayTempState ? setTimeout(setState, 2000) : undefined;
    return () => clearTimeout(timeout);
  }, [state]);

  return [state, setState] as const;
};
